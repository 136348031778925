import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { imsLogo, hide, show } from "../assets";
import { Popup } from "../Components";

const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';


export const ResetPassword = () => {
  const [createPasswordVisible, setCreatePasswordVisible] = useState(false);
  const [enterPasswordVisible, setEnterPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);


  const handleSubmit = e => {
    e.preventDefault();
    setLoader(true);

    const form = new FormData(e.target);
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');

    if (password !== confirmPassword) {
      setPopupData({
        isSuccess: false,
        message: "Passwords do not match"
      });
      setShowPopup(true);
      return
    }

    axios.post(`${apiUrl}/api/user/resetPassword/${token}`, { password }).then(res => {
      console.log(res.data);
      setPopupData({
        isSuccess: true,
        message: res.data.message
      });
      setShowPopup(true);
    }).catch(err => {
      console.error(err);
      setPopupData({
        isSuccess: false,
        message: err.response.data.message
      });
      setShowPopup(true);
    }).finally(() => setLoader(false));
  }

  const closePopup = () => {
    setShowPopup(false);
    popupData.isSuccess && navigate('/')
  }


  return (
    <>
      <div className="min-h-screen grid place-items-center login-sigup-bgImg px-6">
        <form className="border rounded-[--radius-main] bg-white max-w-[450px] w-full px-[30px] lg:px-10 py-12" onSubmit={handleSubmit}>
          <img className="mx-auto" src={imsLogo} alt="sk-logo" />
          <h3 className="text-[15px] font-semibold text-center lg:text-2xl lg:leading-[30px] lg:font-bold mt-3 lg:mt-12 mb-12 lg:mb-6">
            Inventory Management System
          </h3>

          <div className="space-y-3 lg:space-y-4">
            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="password" className="pl-bold">Enter New Password</label>
              <div className="relative">
                <input type={createPasswordVisible ? "text" : "password"}
                  placeholder="apple***" id="password" name="password"
                  className="pl-reg pl-4 pr-10 py-2 w-full"
                  autoComplete="new-password" minLength="6" required
                />

                <div className="absolute inset-y-0 right-3 flex items-center py-1 cursor-pointer">
                  <img src={createPasswordVisible ? show : hide} alt="toggle visibility"
                    className="size-6"
                    onClick={() => setCreatePasswordVisible(!createPasswordVisible)}
                  />
                </div>
              </div>
              <span className="cap-reg text-[--sec-gray]">
                Password must contain letters, numbers, and special symbols
              </span>
            </div>

            <div className="flex flex-col space-y-1 lg:gap-y-2">
              <label htmlFor="confirmPassword" className="pl-bold">Re-enter New Password</label>
              <div className="relative">
                <input type={enterPasswordVisible ? "text" : "password"}
                  placeholder="apple***" id="confirmPassword" name="confirmPassword"
                  className="pl-reg pl-4 pr-10 py-2 w-full"
                  autoComplete="new-password" minLength="6" required
                />
                <div className="absolute inset-y-0 right-3 flex items-center py-1 cursor-pointer">
                  <img src={enterPasswordVisible ? show : hide} alt="toggle visibility"
                    className="size-6"
                    onClick={() => setEnterPasswordVisible(!enterPasswordVisible)}
                  />
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-blue mt-12">Update Password</button>
          {loader && <span className="loader ml-5" />}
        </form>
      </div>

      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={closePopup} />}
    </>
  );
};