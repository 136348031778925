import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAuth } from "../context/AuthContext";
import { imsLogo, hide, show } from "../assets";
import { Loader, Popup } from "../Components";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

export const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);


  const handleLogin = (e) => {
    e.preventDefault();
    setLoader(true);

    const form = new FormData(e.target);
    const user = {
      username: form.get("username").toLowerCase(),
      password: form.get("password"),
    };

    axios.post(`${apiUrl}/api/user/login`, user).then((res) => {
      console.log(res);
      const token = res?.data?.token;
      // Store user data in localStorage
      const userData = {
        ...res.data.user,
        token,
      };
      localStorage.setItem("token", token);
      // Store user data in AuthContext
      login(userData);
      // Navigate to dashboard after login
      navigate("/dashboard");
    }).catch((err) => {
      console.error(err);

      let message = "An unexpected error occurred, Please contact support at contact@skprintingsolution.com";
      if (err.response && err.response.data) {
        message = err.response.data.message || message;
      }

      setShowPopup(true);
      setPopupData({
        isSuccess: false,
        message: message,
      });
    }).finally(() => setLoader(false));
  };


  return (
    <>
      <div className="min-h-screen grid place-items-center login-sigup-bgImg px-6">
        <form
          className="border rounded-[--radius-main] bg-white max-w-[450px] w-full px-[30px] lg:px-10 py-12"
          onSubmit={handleLogin}
        >
          <img className="mx-auto" src={imsLogo} alt="sk-logo" />
          <h3 className="text-[--radius-main] font-semibold text-center lg:text-2xl lg:leading-[30px] lg:font-bold mt-3 lg:mt-12 mb-12 lg:mb-6">
            Inventory Management System
          </h3>

          <div className="space-y-3 lg:space-y-4">
            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="username" className="pl-bold">
                Username
              </label>
              <input
                type="text"
                className="pl-reg"
                placeholder="david123"
                id="username"
                name="username"
                autoComplete="username"
                required
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="password" className="pl-bold">
                Enter Password
              </label>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="pl-reg pl-4 pr-10 py-2 w-full"
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  placeholder="apple***"
                  minLength="6"
                  required
                />
                <div className="absolute inset-y-0 right-3 flex items-center py-1">
                  <img
                    src={passwordVisible ? show : hide}
                    alt="toggle visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="size-6"
                  />
                </div>
              </div>

              <div className="flex justify-between items-center ps-reg">
                <div className="flex lg:gap-x-2 gap-x-1.5">
                  <input type="checkbox" id="remember" name="remember" />
                  <label htmlFor="remember">Remember me</label>
                </div>

                <Link
                  to="/forgot-password"
                  className="text-[--btn-primary-blue]"
                  aria-label="Forgot your password?"
                  title="Forgot your password?"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>
          </div>


          <button className="btn btn-blue mt-12">Login</button>
          
        </form>
      </div>


      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};

export default Login;
