import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { ProfilePopup } from "../Components";

import { imsLogo, profile, navHamburger } from "../assets";

export const Navbar = () => {
  const [showUserProfilePopup, setShowUserProfilePopup] = useState(false);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (showNav) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showNav]);

  return (
    <nav className="w-full nav-shadow z-10">
      <div className="center py-5 px-6 lg:px-[60px] lg:py-[--radius-main] flex justify-between items-center">
        <Link to="/dashboard">
          <img src={imsLogo} alt="sk-logo" />
        </Link>

        <div
          className={`max-lg:absolute top-[72px] left-0 w-full bg-white max-lg:h-[100dvh] z-10 max-lg:px-6 max-lg:py-4 flex max-lg:flex-col lg:items-center lg:justify-center gap-6 max-lg:border-t text-[--text-primary] pl-bold ${
            !showNav && "max-lg:hidden"
          }`}
        >
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Dashboard"
            aria-label="Go to Dashboard"
          >
            Dashboard
          </NavLink>

          <NavLink
            to="/add-user"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Add User Page"
            aria-label="Go to Add User Page"
          >
            Add User
          </NavLink>

          <NavLink
            to="/products"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Products"
            aria-label="Go to Products"
          >
            Products
          </NavLink>

          <NavLink
            to="/sales"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Sales"
            aria-label="Go to Sales"
          >
            Sales
          </NavLink>

          <NavLink
            to="/inventory"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Inventory"
            aria-label="Go to Inventory"
          >
            Inventory
          </NavLink>

          <NavLink
            to="/purchase"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Purchase"
            aria-label="Go to Purchase"
          >
            Purchase
          </NavLink>

          <NavLink
            to="/sales-analysis"
            className={({ isActive }) =>
              `hover:text-[--btn-primary-blue] max-lg:w-fit ${
                isActive && "half-underline"
              }`
            }
            title="Go to Sales analysis"
            aria-label="Go to Sales analysis"
          >
            Sales Analysis
          </NavLink>
        </div>

        <div className="lg:relative flex gap-x-2">
          <img
            src={profile}
            alt="sk-profile"
            className={`cursor-pointer max-lg:size-6 ${
              showUserProfilePopup ? "relative z-10" : ""
            } ${showNav && "max-lg:hidden"}`}
            onClick={() => setShowUserProfilePopup(true)}
          />
          {showUserProfilePopup && (
            <ProfilePopup closePopup={() => setShowUserProfilePopup(false)} />
          )}

          <img
            src={navHamburger}
            alt="Hamburger"
            onClick={() => setShowNav(!showNav)}
            className={`lg:hidden`}
          />
        </div>
      </div>
    </nav>
  );
};
