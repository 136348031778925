import React, { useState } from "react";
import axios from "axios";

import { hide, show } from "../assets";
import { Footer, Loader, Navbar, Popup, Tooltip } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';


export const AddUser = () => {
  const [createPasswordVisible, setCreatePasswordVisible] = useState(false);
  const [enterPasswordVisible, setEnterPasswordVisible] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);


  const signup = (e) => {
    e.preventDefault();
    setLoader(true);

    const form = new FormData(e.target);

    const username = form.get("username").toLowerCase().trim();
    const email = form.get("email").trim();
    const password = form.get("password");
    const role = form.get("role");


    // Validating password contains combination of letters, numbers, and special symbols.
    const hasSpecialSymbol = /[^A-Za-z0-9]/;
    const hasNumber = /\d/;
    if (!hasSpecialSymbol.test(password) && !hasNumber.test(password)) {
      setShowPopup(true);
      setPopupData({
        message: "Password must have letters, numbers and special symbols",
        isSuccess: false
      });
      return;
    }

    // Checking if the passwords are same or not
    if (password !== form.get("confirmPassword")) {
      setPopupData({
        message: "Passwords do not match",
        isSuccess: false,
      });
      return setShowPopup(true);
    }

    const user = { username, email, password, role };

    axios.post(`${apiUrl}/api/user/add-user`, user, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.error(res);
      setPopupData({ isSuccess: true, message: res.data?.message });
      setShowPopup(true);
    }).catch((err) => {
      console.error(err);
      setShowPopup(true);
      throwError(err, setPopupData)
    }).finally(() => {
      document.getElementById("addUserForm").reset();
      setLoader(false);
    });
  };


  return (
    <>
      {/* Navbar */}
      <Navbar />




      {/* Form */}
      <form id="addUserForm"
        className="border rounded-[--radius-main] bg-white lg:w-3/4 pb-12 space-y-12 lg:mx-auto mt-3 lg:mt-12"
        onSubmit={signup}
      >

        <h3 className="bg-[--btn-primary-blue] text-white rounded-t-[--radius-main] text-[--radius-main] font-semibold text-center py-4 lg:text-2xl lg:leading-[30px] lg:font-bold">
          Add User
        </h3>


        <div className="max-lg:space-y-3 lg:grid grid-cols-2 gap-4 px-[30px] lg:px-10">

          <div className="flex flex-col gap-y-1 lg:gap-y-2">

            {/* Tooltip */}
            <div className="flex items-center gap-x-2">
              <label htmlFor="username" className="flex pl-bold gap-x-2">Create Username</label>
              <Tooltip message="Username must be unique and in lower case" />
            </div>

            <input type="text" placeholder="david123" required
              id="username" name="username" className="pl-reg"
            />
          </div>


          <div className="flex flex-col gap-y-1 lg:gap-y-2">
            <label htmlFor="email" className="pl-bold">Enter Email</label>
            <input type="email" placeholder="david123@gmail.com" required
              className="pl-reg" id="email" name="email"
            />
          </div>


          <div className="flex flex-col gap-y-1 lg:gap-y-2">
            <label htmlFor="password" className="pl-bold">Create Password</label>
            <div className="relative">
              <input type={createPasswordVisible ? "text" : "password"}
                className="pl-reg pl-4 pr-10 py-2 w-full" placeholder="apple***"
                id="password" name="password" minLength="6" required
              />

              <div className="absolute inset-y-0 right-3 flex items-center py-1 cursor-pointer">
                <img src={createPasswordVisible ? show : hide} alt="toggle visibility"
                  className="size-6" onClick={() => setCreatePasswordVisible(!createPasswordVisible)}
                />
              </div>
            </div>
            <span className="cap-reg text-[--sec-gray]">
              Password must contain letters, numbers, and special symbols
            </span>
          </div>


          <div className="flex flex-col space-y-1 lg:gap-y-2">
            <label htmlFor="confirmPassword" className="pl-bold">Re-enter Password</label>
            <div className="relative">
              <input type={enterPasswordVisible ? "text" : "password"}
                className="pl-reg pl-4 pr-10 py-2 w-full" placeholder="apple***"
                id="confirmPassword" name="confirmPassword" minLength="6" required
              />
              <div className="absolute inset-y-0 right-3 flex items-center py-1 cursor-pointer">
                <img src={enterPasswordVisible ? show : hide}
                  alt="toggle visibility" onClick={() => setEnterPasswordVisible(!enterPasswordVisible)} className="size-6"
                />
              </div>
            </div>
          </div>


          <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:col-span-2">
            <label htmlFor="role" className="pl-bold">Select Role</label>
            <select name="role" id="role">
              <option value="Admin">Admin</option>
              <option value="Data Analyst">Data Analyst</option>
              <option value="CMS Admin">CMS Admin</option>
              <option value="Salesman">Salesman</option>
              <option value="SK Bot">SK Bot</option>
            </select>
          </div>

        </div>


        <button type="submit" className="btn btn-blue mt-12 mx-auto block">Signup</button>
        {loader && <Loader />}
      </form>


      {/* Footer */}
      <Footer />

      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
    </>
  );
};
