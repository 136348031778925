import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

import { closeWhite, doubleArrow, closeBlack, skLogo } from "../assets";
import { Navbar } from "./Navbar";
import axios from "axios";
import formatDate from "../utils/formatDate";
import ReactToPrint from "react-to-print";

import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";


export const BalanceInquiry = ({ onClose, customerName }) => {
  const [sales, setSales] = useState([]);
  const [customer, setCustomer] = useState("");
  const [message, setMessage] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(() => window.innerWidth < 1023);
  const [position, setPosition] = useState(() => {
    if (window.innerWidth > 1023) {
      const x = (window.innerWidth - 1185) / 2;
      const y = (window.innerHeight - 767) / 2;
      return { x, y };
    }
    return { x: 0, y: 0 };
  });
  const printRef = useRef();


  useEffect(() => {
    setCustomer(customerName.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (match) => match.toUpperCase()));

    axios.post(`${apiUrl}/api/sales/balance-inquiry`, { customerName }, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    }).then(res => {
      console.log(res.data.message);
      setSales(res.data.data);
      setTotalAmount(res.data.totalAmount);
      setTotalCredit(res.data.totalCredit);
      setTotalDebit(res.data.totalDebit);
    }).catch(err => {
      console.error(err);
      setMessage(err.response.data.message || "Something went wrong. Please contact support at contact@skprintingsolution.com");
    })
  }, [customerName]);

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-10" />
      <Draggable defaultPosition={position} disabled={isSmallScreen}>
        <div className="fixed top-0 left-0 max-lg:h-full lg:max-h-[767px] lg:w-[1185px] lg:rounded-[--radius-main] bg-white z-10 !mt-0">
          <div className="lg:hidden">
            <Navbar />
          </div>



          <div className="flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3 className="max-lg:text-base">Balance Inquiry</h3>
            <div
              onClick={onClose}
              className="cursor-pointer hover:bg-[#ffffff0d] content-center"
            >
              <img
                className="hidden lg:block"
                src={closeWhite}
                alt="Close icon"
                title="Close"
              />
              <img
                className="block lg:hidden"
                src={closeBlack}
                alt="Close icon"
                title="Close"
              />
            </div>
          </div>



          <section className="px-[25px] py-4 lg:p-10 space-y-5 lg:space-y-6">
            <form className="max-lg:space-y-3 lg:grid grid-cols-6 lg:gap-x-4 text-base font-semibold">
              <div className="col-span-2 flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="startDate">Start Date</label>
                <input type="date" name="startDate" id="startDate" />
              </div>
              <div className="col-span-2 flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="endDate">End Date</label>
                <input type="date" name="endDate" id="endDate" />
              </div>
              <button type="submit" className="btn btn-blue lg:mt-8 w-full">Search</button>
            </form>


            <div className="flex items-center justify-between">
              <h2 className="max-lg:text-lg">Search result for {customer}</h2>
              {sales.length > 0 &&
                <ReactToPrint
                  trigger={() => (<div className="btn btn-outline max-md:w-[98px] cursor-pointer">Print</div>)}
                  content={() => printRef.current}
                />
              }
            </div>

            <div className="overflow-auto h-sm-balance-inquiry lg:max-h-96 border border-[#00000033]">
              <table className="w-full">
                <thead>
                  <tr>
                    <th id="salesId">
                      <div className="flex justify-between px-4 py-2 cursor-pointer w-max"
                        onClick={() => sortData(sales, setSales, "saleId", "number")}>
                        <span>Sales ID</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="customerName">
                      <div className="flex justify-between px-4 py-2 cursor-pointer w-max">
                        <span>Customer Name</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="item">
                      <div className="flex justify-between px-4 py-2 cursor-pointer w-max"
                        onClick={() => sortData(sales, setSales, "item", "string")}>
                        <span>Item</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="payMethod">
                      <div className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                        onClick={() => sortData(sales, setSales, "paymentMethod", "string")}>
                        <span>Payment Method</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="saleAmount">
                      <div className="flex justify-between px-4 py-2 cursor-pointer w-max"
                        onClick={() => sortData(sales, setSales, "saleAmount", "number")}>
                        <span>Sale Amount</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="creditAmount">
                      <div className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                        onClick={() => sortData(sales, setSales, "creditAmount", "number")}>
                        <span>Credit Amount</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="debitAmount">
                      <div className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                        onClick={() => sortData(sales, setSales, "debitAmount", "number")}>
                        <span>Debit Amount</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>

                    <th id="saleDate">
                      <div className="flex justify-between px-4 py-2 cursor-pointer w-max"
                        onClick={() => sortData(sales, setSales, "saleDate", "date")}>
                        <span>Sale Date</span>
                        <img src={doubleArrow} alt="Sort Icon" />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {sales?.length > 0 ? (
                    sales?.map((sale) => (
                      <tr key={sale._id} className="*:px-4 *:py-2 h-11 ps-reg">
                        <td headers="salesId">
                          {sale.saleId < 100 ?
                            sale.saleId < 10 ? "00" + sale.saleId : "0" + sale.saleId
                            : sale.saleId
                          }
                        </td>

                        <td headers="customerName"> {sale.customerName?.join(" ")}</td>

                        <td headers="item">
                          {sale.products.map((el, index) => (
                            <div key={el._id} className="w-max">
                              {el?.productQuantity} {el?.product?.productName} (Rs. {el?.product?.sellingPrice} / unit) = Rs. {el?.totalCost}
                              {index < sale.products.length - 1 && ", "}
                            </div>
                          ))}
                        </td>

                        <td headers="payMethod"> {sale.paymentMethod}</td>

                        <td headers="saleAmount"> {sale.saleAmount}</td>

                        <td headers="creditAmount"> {sale.creditAmount}</td>

                        <td headers="debitAmount"> {sale.debitAmount}</td>

                        <td headers="saleDate"> {formatDate(sale.saleDate)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-[#E83535] font-bold p-4">
                        {message}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {sales.length > 0 &&
              <div className="bg-[--primary-gray]">
                <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x">
                  <h3 colSpan={7} className="col-span-7 lg:col-span-8 text-lg text-right">Total Amount</h3>
                  <p className="col-span-3 lg:col-span-2">Rs. {totalAmount}</p>
                </div>
                <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x bg-white">
                  <h3 colSpan={7} className="col-span-7 lg:col-span-8 text-lg text-right">Total Credit</h3>
                  <p className="col-span-3 lg:col-span-2">Rs. {totalCredit}</p>
                </div>
                <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x">
                  <h3 colSpan={7} className="col-span-7 lg:col-span-8 text-lg text-right">Total Debit</h3>
                  <p className="col-span-3 lg:col-span-2">Rs. {totalDebit}</p>
                </div>
              </div>
            }
          </section>





          {/* Table to print */}
          <section ref={printRef} className="p-5 print:block hidden space-y-8 relative">
            <div className="flex justify-between items-center">
              <img src={skLogo} alt="SK Printing Solution" className="h-10" />
              <h2>Customer Balance Detail - {customer}</h2>
              <p className="ps-bold">Date:{formatDate(Date.now())}</p>
            </div>

            <table className="*:*:p-2 w-full text-xs border border-[#00000033]">
              <tr className="bg-[--primary-gray]">
                <th>ID</th>
                <th>Item</th>
                <th>Details</th>
                <th>Pay mode</th>
                <th>Sales</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>Date</th>
              </tr>
              {sales?.map((sale, i) => (
                <tr key={sale._id} className={`*:px-4 *:py-2 h-11 ps-reg ${i % 2 !== 0 && "bg-[--primary-gray]"}`}>
                  <td>
                    {sale.saleId < 100 ?
                      sale.saleId < 10 ? "00" + sale.saleId : "0" + sale.saleId
                      : sale.saleId
                    }
                  </td>
                  <td>
                    {sale.products.map(el => (
                      <div key={el._id} className="w-max">
                        <b>{el?.product?.productName}</b><br />
                        {el?.productQuantity} x Rs. {el?.product?.sellingPrice} = Rs. {el?.totalCost}
                      </div>
                    ))}
                  </td>
                  <td>
                    {sale.amountHistory.map(el => (
                      <div key={el._id} className="w-max">
                        {formatDate(el?.transactionDate)} - Rs. {el?.credit} - Rs. {el?.debit}
                      </div>
                    ))}
                  </td>
                  <td>{sale.paymentMethod}</td>
                  <td>{sale.saleAmount}</td>
                  <td>{sale.creditAmount}</td>
                  <td>{sale.debitAmount}</td>
                  <td>{formatDate(sale.saleDate)}</td>
                </tr>
              ))}
            </table>

            <div className="bg-[--primary-gray]">
              <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#838383] divide-x h-11">
                <h3 className="col-span-8 text-lg text-right">Total Amount</h3>
                <p className="col-span-2">Rs. {totalAmount}</p>
              </div>
              <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#838383] divide-x h-11 bg-white">
                <h3 className="col-span-8 text-lg text-right">Total Credit</h3>
                <p className="col-span-2">Rs. {totalCredit}</p>
              </div>
              <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#838383] divide-x h-11">
                <h3 className="col-span-8 text-lg text-right">Total Debit</h3>
                <p className="col-span-2">Rs. {totalDebit}</p>
              </div>
            </div>
          </section>
        </div>
      </Draggable>
    </>
  );
};