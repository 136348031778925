import React, { useEffect, useState } from "react";
import axios from "axios";

import { Navbar, Footer, Popup, Loader } from "../Components";
import { doubleArrow, search, deleteIcon, edit } from "../assets";

import throwError from "../utils/catch";
import sortData from "../utils/sortData";
import formatDate from "../utils/formatDate";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

export const Purchase = () => {
  const [purchases, setPurchases] = useState([]);
  const [purchaseForm, setPurchaseForm] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => getPurchaseProducts(), []);

  const getPurchaseProducts = () => {
    axios
      .get(`${apiUrl}/api/purchase/get`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res.data.message);
        setPurchases(res.data.purchases);
      })
      .catch((err) => {
        console.error(err);
        throwError(err, setPopupData);
      });
  };

  const deletePurchase = (id) => {
    setLoader(true);
    axios
      .delete(`${apiUrl}/api/purchase/delete/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res.data);
        getPurchaseProducts();
      })
      .catch((err) => {
        console.error(err);
        throwError(err, setPopupData);
        setShowPopup(true);
      })
      .finally(() => setLoader(false));
  };

  const editPurchase = (pur) => {
    setPurchases(pur);
    setPurchaseForm(true);
  };

  const filteredPurchases = purchases.filter((purchase) => {
    return purchase?.products?.some((product) =>
      product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      <Navbar />

      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">
        <section>
          <div className="bg-[--btn-primary-blue]  px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input
                className="rounded-[22px] pl-reg lg:pr-10"
                type="text"
                placeholder="Search Item"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <img
                src={search}
                alt="search"
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="overflow-x-auto mb-4 lg:mb-16">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="supplierName">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() =>
                        sortData(purchases, setPurchases, "supplierName", "string")
                      }
                    >
                      <span>Supplier Name</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="item">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "item", "string")}
                    >
                      <span>Item</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="totalAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "totalAmount", "number")}
                    >
                      <span>Total Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="paymentMethod">
                    <div
                      className="flex text-nowrap justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "paymentMethod", "string")}
                    >
                      <span>Payment Method</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="purchaseDate">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "purchaseDate", "date")}
                    >
                      <span>Purchase Date</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {Array.isArray(filteredPurchases) && filteredPurchases.length !== 0 ? (
                  filteredPurchases.map((purchase) => (
                    <tr key={purchase?._id} className="*:px-4 *:py-2 h-11 ps-reg">
                      <td headers="supplierName">{purchase?.supplierName}</td>

                      <td headers="item">
                        {purchase?.products?.map((item) => (
                          <div key={item._id} className="w-max">
                            {item?.name} - {item?.itemQuantity} - Rs. {item?.totalCost}
                          </div>
                        ))}
                      </td>

                      <td headers="totalAmount">{purchase?.totalAmount}</td>

                      <td headers="paymentMethod">{purchase?.paymentMethod}</td>

                      <td headers="purchaseDate">
                        {formatDate(purchase?.purchaseDate)}
                      </td>

                      <td headers="actions" className="flex gap-x-2">
                        <img
                          src={edit}
                          alt="Edit button"
                          className="inline-block cursor-pointer"
                          onClick={() => editPurchase(purchase)}
                        />
                        <img
                          src={deleteIcon}
                          alt="Delete button"
                          className="inline-block cursor-pointer"
                          onClick={() => deletePurchase(purchase?._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-[#E83535] font-bold p-4">
                      No Purchases Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>

      <Footer />

      {/* Popup */}
      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};
