const throwError = (err, setPopupData) => {
    if (err.response.status === 403) {
        setPopupData({
            isSuccess: false,
            message: `You are a ${err.response.data.role}, therefore you're not authorized to view this data.`
        });
    } else if (err.response.status === 401) {
        setPopupData({
            isSuccess: false,
            message: "You are not authorized to view this data."
        });
    } else {
        setPopupData({
            isSuccess: false,
            message: err.response.data.message || "Something went wrong. Please contact support at contact@skprintingsolution.com"
        });
    }
}

export default throwError