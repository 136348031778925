import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../context/AuthContext";

import { profile, logoutUser } from "../assets";

export const ProfilePopup = ({ closePopup }) => {
  const { user } = useAuth();
  const { logout } = useAuth();

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-20 z-10"
        onClick={() => closePopup()}
      />

      <div className="space-y-4 p-4 rounded-[--radius-main] w-full lg:w-[350px] absolute top-[72px] lg:top-10 max-lg:left-0 lg:right-0 bg-white z-20">
        <div className="flex justify-between pb-4 border-b border-[#AFB3B8]">
          <div className="flex gap-x-2">
            <img className="size-10" src={profile} alt="profile" />
            <div className="space-y-1">
              <div className="pl-bold text-[--text-primary]">
                Username - {user.username}
              </div>
              <div className="ps-reg text-[--text-sec] pb-2">
                Role - {user.role}
              </div>
              <Link
                to="/profile-page"
                className="text-[--btn-hover-primary-blue] underline w-16 text-base"
              >
                View Profile
              </Link>
            </div>
          </div>

          <Link
            to="/forgot-password"
            className="text-[--btn-hover-primary-blue] underline w-16 text-xs"
          >
            Change Password
          </Link>
        </div>

        <Link to="/" onClick={logout} className="flex gap-x-4">
          <img src={logoutUser} alt="logout" />
          <span className="text-[--text-primary]">Logout</span>
        </Link>
      </div>
    </>
  );
};
