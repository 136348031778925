import React, { useEffect, useState } from "react";
import axios from "axios";

import { Navbar, Footer, InventoryForm, Popup, PurchaseDataForm, Loader } from "../Components";
import { doubleArrow, search, deleteIcon, edit } from "../assets";
import throwError from "../utils/catch";
import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";


export const Inventory = () => {
  const [products, setProducts] = useState([]);
  const [inventoryForm, setInventoryForm] = useState(false);
  const [purchaseForm, setPurchaseForm] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  useEffect(() => getInventoryProducts(), []);

  const getInventoryProducts = () => {
    axios.get(`${apiUrl}/api/inventory/get`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      setProducts(res.data.inventory);
      console.log(res.data.message);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setProducts([]);
    });
  };

  const deleteInventory = (id) => {
    setLoader(true);

    axios.delete(`${apiUrl}/api/inventory/delete/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      console.log(res.data);
      getInventoryProducts();
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const editInventory = (product) => {
    setProductToUpdate(product);
    setInventoryForm(true);
  };

  const closeForm = (setForm) => {
    setForm(false);
    getInventoryProducts();
  };

  const filteredProducts = products.filter((product) => product.productName.toLowerCase().includes(searchTerm.toLowerCase()));


  return (
    <>
      {/* Navbar */}
      <Navbar />



      {/* Main */}
      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">

        {/* Cards */}
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-5">

          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px]">
            <h2 className="text-base lg:text-[28px] lg:leading-[34px]">
              Inventory Form
            </h2>
            <button
              onClick={() => setInventoryForm(!inventoryForm)}
              className="btn btn-blue text-xs lg:text-base w-full lg:w-[139px]"
            >
              Add Entry
            </button>
          </div>


          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px]">
            <h2 className="text-base lg:text-[28px] lg:leading-[34px]">
              Purchased Data Form
            </h2>
            <button
              onClick={() => setPurchaseForm(true)}
              className="btn btn-blue text-xs lg:text-base w-full lg:w-[139px]"
            >
              Add Entry
            </button>
          </div>


          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px]">
            <h2 className="text-base lg:text-[28px] lg:leading-[34px]">
              Find Product
            </h2>
            <input
              className="rounded w-full lg:w-[280px]"
              type="text"
              name=""
              id=""
              placeholder="INK"
            />
            <button className="btn btn-blue text-xs lg:text-base w-full lg:w-[139px]">Find Product</button>
          </div>

        </section>




        {/* Table */}
        <section>
          <div className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input
                className="rounded-[22px] pl-reg lg:pr-10"
                type="text"
                placeholder="Search Product"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img
                src={search}
                alt="search"
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="overflow-x-auto mb-4 lg:mb-16">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="productCode">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "productCode", "string")}
                    >
                      <span>Product Code</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="productName">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "productName", "string")}
                    >
                      <span>Product Name</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="qty">
                    <div
                      className="flex text-nowrap justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "stock", "number")}
                    >
                      <span>Quantity in Stock</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="category">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "category", "string")}
                    >
                      <span>Category</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="costPrice">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "costPrice", "number")}
                    >
                      <span>Cost Price</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="sellingPrice">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "sellingPrice", "number")}
                    >
                      <span>Selling Price</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="inventoryManager">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "inventoryManager", "string")}
                    >
                      <span>Inventory Manager</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {filteredProducts.length !== 0 ? (
                  filteredProducts.map((product) => (
                    <tr
                      key={product?._id}
                      className="*:px-4 *:py-2 h-11 ps-reg"
                    >
                      <td headers="productCode">{product?.productCode}</td>

                      <td headers="productName">{product?.productName}</td>

                      <td headers="qty">{product?.stock}</td>

                      <td headers="category">{product?.category}</td>

                      <td headers="costPrice">{product?.costPrice}</td>

                      <td headers="sellingPrice">{product?.sellingPrice}</td>

                      <td headers="inventoryManager">{product?.inventoryManager}</td>

                      <td headers="actions" className="flex gap-x-2">
                        <img
                          src={edit}
                          alt="Edit button"
                          className="inline-block cursor-pointer"
                          onClick={() => editInventory(product)}
                        />
                        <img
                          src={deleteIcon}
                          alt="Delete button"
                          className="inline-block cursor-pointer"
                          onClick={() => deleteInventory(product?._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-[#E83535] font-bold p-4">
                      No products found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>



      {/* Footer */}
      <Footer />



      {/* Forms */}
      {inventoryForm && <InventoryForm onClose={() => closeForm(setInventoryForm)} productToUpdate={productToUpdate} />}
      {purchaseForm && <PurchaseDataForm onClose={() => closeForm(setPurchaseForm)} />}

      {/* Popup */}
      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};
