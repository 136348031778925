import React from "react";

import { useAuth } from "../context/AuthContext";

import { Footer, Navbar } from "../Components";

export const ProfilePage = () => {
  const { user } = useAuth();

  return (
    <>
      {/* Navbar */}
      <Navbar />

      <div className="center border rounded-[--radius-main] bg-white lg:w-3/4 pb-12 lg:space-y-12 lg:mx-auto mt-3 lg:mt-12">
        <h3 className="mb-10 bg-[--btn-primary-blue] text-white rounded-t-[--radius-main] text-[--radius-main] font-semibold text-center py-4 lg:text-2xl lg:leading-[30px] lg:font-bold">
          Your Profile
        </h3>
        <div className="space-y-4 lg:text-lg text-sm max-lg:space-y-3 px-[30px] lg:px-10">
          <div>
            <span className="font-bold text-[--btn-primary-blue] lg:text-xl pr-1">
              Full Name:
            </span>{" "}
            {user.fullname}
          </div>
          <div>
            <span className="font-bold text-[--btn-primary-blue] lg:text-xl pr-1">
              User Name:
            </span>{" "}
            {user.username}
          </div>
          <div>
            <span className="font-bold text-[--btn-primary-blue] lg:text-xl pr-1">
              Role:
            </span>{" "}
            {user.role}
          </div>
          <div>
            <span className="font-bold text-[--btn-primary-blue] lg:text-xl pr-1">
              Email:
            </span>{" "}
            {user.email}
          </div>
          <div>
            <span className="font-bold text-[--btn-primary-blue] lg:text-xl pr-1">
              Phone Number:
            </span>{" "}
            {user.phoneNumber}
          </div>
          <div>
            <span className="font-bold text-[--btn-primary-blue] lg:text-xl pr-1">
              Address:
            </span>{" "}
            {user.address}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};
