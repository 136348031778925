let sortConfig = {
    key: null,
    direction: true
}

const sortData = (data, setData, key, type) => {
    let sortedData = [...data];

    if (key === "item") {
        sortedData.sort((a, b) => {
            const valueA = a.products.map((el) => el?.product?.productName || "").join(", ");
            const valueB = b.products.map((el) => el?.product?.productName || "").join(", ");

            return sortConfig.direction ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        });
    } else if (type === "string") {
        sortedData.sort((a, b) => {
  
          const valueA = String(a[key] || "");
          const valueB = String(b[key] || "");
    
          if (sortConfig.direction === "asc") {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        });
      } else if (type === "number") {
        sortedData.sort((a, b) => {
            const valueA = a[key] !== undefined ? a[key] : 0;
            const valueB = b[key] !== undefined ? b[key] : 0;

            return sortConfig.direction ? valueA - valueB : valueB - valueA;
        });
    } else if (type === "date") {
        sortedData.sort((a, b) => {
            const dateA = a[key] ? new Date(a[key]) : new Date(0);
            const dateB = b[key] ? new Date(b[key]) : new Date(0);

            return sortConfig.direction ? dateA - dateB : dateB - dateA;
        });
    }

    setData(sortedData);
    sortConfig = {
        key,
        direction: sortConfig.direction === "asc" ? "desc" : "asc",
    }
};

export default sortData;