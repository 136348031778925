import React, { useState } from "react";
import axios from "axios";
import Draggable from "react-draggable";

import { closeWhite, closeBlack } from "../assets";
import { Loader, Navbar, Popup } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

export const UpdateBalance = ({ onClose }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(() => window.innerWidth < 1023);
  const [position, setPosition] = useState(() => {
    if (window.innerWidth > 1023) {
      const x = (window.innerWidth - 500) / 2;
      const y = (window.innerHeight - 509) / 2;
      return { x, y };
    }
    return { x: 0, y: 0 };
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    const formData = new FormData(e.target);
    let payload = {};
    formData.forEach((value, key) => (payload[key] = value));
    payload.amountPaid = Number(payload.amountPaid);
    payload.saleId = Number(payload.saleId);

    axios.put(`${apiUrl}/api/sales/updatebalance`, payload, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data);
      setShowPopup(true);
      setPopupData({
        isSuccess: true,
        message: "Customer's balance updated successfully",
      });
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const closePopups = () => {
    setShowPopup(false);
    onClose();
  };


  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-10" />
      <Draggable defaultPosition={position} disabled={isSmallScreen}>
        <form className="fixed top-0 left-0 w-full lg:w-[500px] lg:rounded-[--radius-main] bg-white z-10 !mt-0" onSubmit={handleSubmit}>
          <div className="lg:hidden"><Navbar /></div>


          <div className="flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>Update Balance Form</h3>
            <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <img src={closeWhite} alt="Close icon" title="Close" className="max-lg:hidden" />
              <img src={closeBlack} alt="Close icon" title="Close" className="lg:hidden" />
            </div>
          </div>


          <div className="h-sm-form">
            <div className="max-lg:mt-4 pb-4 px-6 lg:p-10 grid grid-cols-2 gap-y-3 gap-x-2 lg:gap-5 text-base font-semibold text-[--text-primary]">
              <div className="flex flex-col gap-y-2">
                <label htmlFor="saleId">Sale I.D*</label>
                <input
                  type="number"
                  placeholder="1204"
                  name="saleId"
                  id="saleId"
                  required
                />
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="paymentMethod">Payment Method*</label>
                <select name="paymentMethod" id="paymentMethod">
                  <option value="Cash">Cash</option>
                  <option value="Online">Online</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="amountPaid">Amount Paid*</label>
                <input
                  type="number"
                  placeholder="10000"
                  name="amountPaid"
                  id="amountPaid"
                  required
                />
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="paymentStatus">Payment Status*</label>
                <select name="paymentStatus" id="paymentStatus">
                  <option>Paid</option>
                  <option>Partially Paid</option>
                </select>
              </div>
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">Add Entry</button>
            <button type="reset" className="btn btn-outline">Reset</button>
          </div>
        </form>
      </Draggable>

      {showPopup && (
        <Popup
          isSuccess={popupData.isSuccess}
          message={popupData.message}
          onClose={closePopups}
        />
      )}
      {loader && <Loader />}
    </>
  );
};