import React, { useState, useEffect } from "react";
import axios from "axios";

import Draggable from "react-draggable";

import { Navbar, Popup } from "../Components";
import { closeWhite, crossGray, attach, closeBlack } from "../assets";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

export const AddProductPopup = ({ onClose, product = null }) => {
  const [formValues, setFormValues] = useState({
    productCode: "",
    name: "",
    category: "",
    description: "",
    brand: "",
    modelNo: "",
    quantity: "",
    dimension: "",
    price: "",
    details: "",
  });
  const [images, setImages] = useState(["Image 1", "Image 2", "Image 3", "Image 4"]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });

  const [isSmallScreen, setIsSmallScreen] = useState(() => window.innerWidth < 1023);
  const [position, setPosition] = useState(() => {
    if (window.innerWidth > 1023) {
      const x = (window.innerWidth - 940) / 2;
      const y = (window.innerHeight - 685) / 2;
      return { x, y };
    }
    return { x: 0, y: 0 };
  });

  useEffect(() => {
    if (product) {
      setFormValues({
        productCode: product?.productCode,
        name: product?.name,
        category: product?.category,
        description: product?.description,
        brand: product?.brand,
        modelNo: product?.modelNo,
        quantity: product?.quantity || "",
        dimension: product?.dimension || "",
        price: product?.price,
        details: product?.details,
      });
      setImages(product?.images);
    }
  }, [product]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formValues.quantity.trim() !== "" &&
      formValues.dimension.trim() !== ""
    ) {
      setShowPopup(true);
      setPopupData({
        isSuccess: false,
        message: "Please provide either quantity or dimension",
      });
      return;
    }

    formValues["images"] = images;

    if (product) {
      axios
        .put(
          `${apiUrl}/api/product/update/${product?._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
          formValues
        )
        .then((res) => {
          console.log(res.data);
          onClose();
        })
        .catch((err) => console.error(err.response));
    } else {
      axios
        .post(`${apiUrl}/api/product/add`, formValues, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          console.log(res.data);
          onClose();
        })
        .catch((err) => console.error(err));
    }
  };

  const handleInputChange = (e) =>
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  const closePopup = () => setPopupData({ ...popupData, show: false });

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-10 !mt-0" />
      <Draggable defaultPosition={position} disabled={isSmallScreen}>
        <form
          className="fixed top-0 left-0 w-full lg:w-[940px] lg:rounded-[--radius-main] bg-white z-10 !mt-0"
          onSubmit={handleSubmit}
        >
          <div className="lg:hidden">
            <Navbar />
          </div>

          <div className="flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>{product ? "Update" : "Add"} Product Form</h3>
            <div
              onClick={onClose}
              className="cursor-pointer hover:bg-[#ffffff0d] content-center"
            >
              <img
                src={closeWhite}
                alt="Close icon"
                title="Close"
                className="max-lg:hidden"
              />
              <img
                src={closeBlack}
                alt="Close icon"
                title="Close"
                className="lg:hidden"
              />
            </div>
          </div>

          <div className="h-sm-form">
            <div className="max-lg:mt-4 pb-4 px-6 lg:p-10 grid grid-cols-2 lg:grid-cols-4 gap-y-3 lg:gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold text-[--text-primary] overflow-y-auto">
              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="productCode">Product Code*</label>
                <input
                  type="text"
                  placeholder="ink001"
                  name="productCode"
                  id="productCode"
                  required
                  value={formValues.productCode}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  placeholder="Galaxy Echo.."
                  name="name"
                  id="name"
                  required
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="name">Category*</label>
                <input
                  type="text"
                  placeholder="Galaxy"
                  name="category"
                  id="category"
                  required
                  value={formValues.category}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="brand">Brand*</label>
                <input
                  type="text"
                  placeholder="Galaxy"
                  name="brand"
                  id="brand"
                  required
                  value={formValues.brand}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2">
                <label htmlFor="description">Meta Description*</label>
                <input
                  type="text"
                  placeholder="High Quality Original Galaxy Printer DX4.."
                  name="description"
                  id="description"
                  required
                  value={formValues.description}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="modelNo">Model No*</label>
                <input
                  type="text"
                  placeholder="DX4/DX5"
                  name="modelNo"
                  id="modelNo"
                  required
                  value={formValues.modelNo}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="quantity">Quantity</label>
                <input
                  type="text"
                  placeholder="1000ml"
                  name="quantity"
                  id="quantity"
                  value={formValues.quantity}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="dimension">Dimension</label>
                <input
                  type="text"
                  placeholder="24’ x 60’ inches"
                  name="dimension"
                  id="dimension"
                  value={formValues.dimension}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="price">Price*</label>
                <input
                  type="number"
                  placeholder="2200"
                  name="price"
                  id="price"
                  required
                  value={formValues.price}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2">
                <label htmlFor="details">Details*</label>
                <textarea
                  rows={1}
                  placeholder="Enter Product Details"
                  name="details"
                  id="details"
                  required
                  value={formValues.details}
                  onChange={handleInputChange}
                />
              </div>

              <div className="space-y-3 lg:space-y-6 col-span-2 lg:col-span-4">
                <button type="button" className="btn btn-outline flex justify-center items-center gap-x-2.5">
                  <img src={attach} alt="Attach files" />
                  <span>Add Images</span>
                </button>

                <div className="flex gap-x-2">
                  <div className="size-20 border border-[#00000099] relative">
                    <img src={attach} alt="Product" className="w-full" />
                    <img
                      src={crossGray}
                      alt="Close icon"
                      title="Remove image"
                      className="absolute top-0 right-0 cursor-pointer"
                    />
                  </div>
                  <div className="size-20 border border-[#00000099] relative">
                    <img src={attach} alt="Product" className="w-full" />
                    <img
                      src={crossGray}
                      alt="Close icon"
                      title="Remove image"
                      className="absolute top-0 right-0 cursor-pointer"
                    />
                  </div>
                  <div className="size-20 border border-[#00000099] relative">
                    <img src={attach} alt="Product" className="w-full" />
                    <img
                      src={crossGray}
                      alt="Close icon"
                      title="Remove image"
                      className="absolute top-0 right-0 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">{product ? "Update" : "Add"} Product</button>
            <button type="reset" className="btn btn-outline">Reset</button>
          </div>
        </form>
      </Draggable>

      {showPopup && (
        <Popup
          isSuccess={popupData.isSuccess}
          message={popupData.message}
          onClose={closePopup}
        />
      )}
    </>
  );
};