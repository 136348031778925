import img_attach from "./attach.svg";
import img_closeBlack from "./close-black.svg";
import img_closeRed from "./close-red.svg";
import img_closeWhite from "./close-white.svg";
import img_crossGray from "./cross-gray.svg";
import img_cross from "./cross.svg";
import img_deleteIcon from "./deleteIcon.svg";
import img_doubleArrow from "./doubleArrow.svg";
import img_edit from "./edit.svg";
import img_error from "./error.svg";
import img_hide from "./hide.svg";
import img_imsBg from "./ims-bg.png";
import img_imsLogo from "./ims-logo.png";
import img_itemsSold from "./items-sold.svg";
import img_logoutUser from "./logoutUser.svg";
import img_navHamburger from "./nav-hamburger.svg";
import img_print from "./print.svg"
import img_profileEdit from "./profile-edit.svg";
import img_profile from "./profile.png";
import img_search from "./search.svg";
import img_show from "./show.svg";
import img_skLogo from "./sk-logo.png";
import img_success from "./success.svg";
import img_tooltip from "./tooltip.svg";
import img_totalSale from "./total-sale.svg";
import img_totalStock from "./total-stock.svg";

export const attach = img_attach,
  closeBlack = img_closeBlack,
  closeRed = img_closeRed,
  closeWhite = img_closeWhite,
  crossGray = img_crossGray,
  cross = img_cross,
  deleteIcon = img_deleteIcon,
  doubleArrow = img_doubleArrow,
  edit = img_edit,
  error = img_error,
  hide = img_hide,
  imsBg = img_imsBg,
  imsLogo = img_imsLogo,
  itemsSold = img_itemsSold,
  logoutUser = img_logoutUser,
  navHamburger = img_navHamburger,
  print = img_print,
  profileEdit = img_profileEdit,
  profile = img_profile,
  search = img_search,
  show = img_show,
  skLogo = img_skLogo,
  success = img_success,
  tooltip = img_tooltip,
  totalSale = img_totalSale,
  totalStock = img_totalStock;