import React, { useState } from "react";

import axios from "axios";

import { imsLogo } from "../assets";
import { Loader, Popup } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';


export const ForgotPassword = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);


  const handleSubmit = e => {
    e.preventDefault();
    setLoader(true);
    
    const email = e.target[0].value;
    axios.post(`${apiUrl}/api/user/forgotPassword`, { email }).then(res => {
      console.log(res.data);
      setPopupData({ isSuccess: true, message: res.data.message });
      setShowPopup(true);
    }).catch(err => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  }


  return (
    <>
      <div className="min-h-screen grid place-items-center login-sigup-bgImg px-6">
        <form className="border rounded-[--radius-main] bg-white max-w-[450px] w-full px-[30px] lg:px-10 py-12" onSubmit={handleSubmit}>
          <img className="mx-auto" src={imsLogo} alt="sk-logo" />
          <h3 className="text-[15px] font-semibold text-center lg:text-2xl lg:leading-[30px] lg:font-bold mt-3 lg:mt-12 mb-12 lg:mb-6">
            Inventory Management System
          </h3>

          <p className="my-6 text-center">Please enter your email address to receive a password reset link.</p>

          <div className="flex flex-col gap-y-1 lg:gap-y-2">
            <label htmlFor="email" className="pl-bold">
              Email
            </label>
            <input type="email" placeholder="david123@gmail.com"
              id="email" name="email" className="pl-reg"
              autoComplete="email" required
            />
          </div>

          <button type="submit" className="btn btn-blue mt-12 text-nowrap">Send Email</button>
          
        </form>
      </div>


      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};