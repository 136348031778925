import React, { useEffect, useState } from "react";
import axios from "axios";
import Draggable from "react-draggable";

import { closeRed, closeWhite, closeBlack } from "../assets";
import { Loader, Navbar, Popup } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

export const SalesEntryForm = ({ onClose }) => {
  const [saleData, setSaleData] = useState({
    customerName: "",
    contactNumber: "",
    saleDate: "",
    paymentMethod: "Cash",
    saleAmount: null,
    creditAmount: null,
    debitAmount: null,
    orderDate: "",
    paymentDueDate: "",
    paymentReceivedDate: "",
    salesTax: null,
    paymentStatus: "Paid",
    salesPerson: "",
    comment: "",
  });
  const [products, setProducts] = useState([
    { product: {}, productQuantity: null, totalCost: null },
  ]);
  const [inventoryProducts, setInventoryProducts] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(() => window.innerWidth < 1023);
  const [position, setPosition] = useState(() => {
    if (window.innerWidth > 1023) {
      const x = (window.innerWidth - 940) / 2;
      const y = (window.innerHeight - 767) / 2;
      return { x, y };
    }
    return { x: 0, y: 0 };
  });


  useEffect(() => getInventoryProducts(), []);

  useEffect(() => {
    const totalAmount = products.reduce((total, item) => total + (item.totalCost || 0), 0);
    setSaleData((prev) => ({ ...prev, saleAmount: totalAmount }));
  }, [products]);

  useEffect(() => {
    const debitAmount = (saleData.saleAmount || 0) - (saleData.creditAmount || 0);
    setSaleData((prev) => ({ ...prev, debitAmount }));
  }, [saleData.saleAmount, saleData.creditAmount]);

  const getInventoryProducts = () => {
    axios.get(`${apiUrl}/api/inventory/get`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      setInventoryProducts(res.data.inventory);
      setProducts([{
        product: res.data.inventory[0],
        productQuantity: "",
        totalCost: "",
      }]);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    });
  };

  const handleChange = (index, field, value) => {
    if (field === "product") value = JSON.parse(value);
    const newItems = [...products];
    newItems[index][field] = value;
    setProducts(newItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    axios.post(`${apiUrl}/api/sales/add`, {
      ...saleData, products
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then(() => {
      onClose();
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(true));
  };

  const addItem = () => setProducts([...products, { productName: inventoryProducts[0], productQuantity: null, totalCost: null, }]);
  const removeItem = (index) => products.length > 1 && setProducts(products.filter((_, i) => i !== index));


  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-10" />
      <Draggable defaultPosition={position} disabled={isSmallScreen}>
        <form
          className="fixed top-0 left-0 w-full lg:w-[940px] lg:rounded-[--radius-main] bg-white z-10 !mt-0"
          onSubmit={handleSubmit}
        >
          <div className="lg:hidden">
            <Navbar />
          </div>

          <div className="flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>Sales Entry Form</h3>
            <div
              onClick={onClose}
              className="cursor-pointer hover:bg-[#ffffff0d] content-center"
            >
              <img
                src={closeWhite}
                alt="Close icon"
                title="Close"
                className="max-lg:hidden"
              />
              <img
                src={closeBlack}
                alt="Close icon"
                title="Close"
                className="lg:hidden"
              />
            </div>
          </div>

          <div className="h-sm-form lg:max-h-[600px] overflow-y-auto max-lg:mt-4 px-6 pb-4 lg:p-10 space-y-4 lg:space-y-6">
            <div className="bg-[--primary-gray] grid grid-cols-1 gap-y-6 p-4 lg:px-5 lg:py-[18px] rounded-md">
              <div className="flex lg:justify-end max-lg:order-1">
                <button
                  onClick={addItem}
                  className="btn btn-outline bg-white max-lg:w-full max-lg:px-6 max-lg:py-3"
                >
                  + Add Item
                </button>
              </div>

              {products.map((item, i) => (
                <div
                  key={i}
                  className={`grid grid-cols-2 lg:flex gap-y-3 gap-x-2 lg:gap-x-5 items-end text-base font-semibold max-lg:pb-4 ${i > 0 &&
                    "max-lg:border-t max-lg:border-[#848F99] max-lg:pt-2.5"
                    }`}
                >
                  <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:w-[266px] col-span-4 lg:grow">
                    <label htmlFor={`product${i}`}>Product*</label>
                    <select
                      name={`product${i}`}
                      id={`product${i}`}
                      onChange={(e) =>
                        handleChange(i, "product", e.target.value)
                      }
                    >
                      {inventoryProducts.map((inventory) => (
                        <option
                          key={inventory._id}
                          value={JSON.stringify(inventory)}
                        >
                          {inventory.productName} - Code:{" "}
                          {inventory.productCode} - Rs.{inventory.sellingPrice}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[150px]">
                    <label htmlFor={`productQuantity${i}`}>
                      Product Quantity*
                    </label>
                    <input
                      type="number"
                      placeholder="10"
                      name={`productQuantity${i}`}
                      id={`productQuantity${i}`}
                      required
                      value={item.productQuantity}
                      onChange={(e) => handleChange(i, "productQuantity", Number(e.target.value))}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[150px]">
                    <label htmlFor={`totalCost${i}`}>Total Cost*</label>
                    <input
                      type="number"
                      placeholder="10"
                      name={`totalCost${i}`}
                      id={`totalCost${i}`}
                      required
                      value={item.totalCost}
                      onChange={(e) => handleChange(i, "totalCost", Number(e.target.value))}
                    />
                  </div>

                  <img
                    src={closeRed}
                    alt="close"
                    className="cursor-pointer pb-2 max-lg:-order-1 max-lg:col-span-3 max-lg:justify-self-end"
                    onClick={() => removeItem(i)}
                  />
                </div>
              ))}
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold text-[--text-primary]">
              <div className="flex flex-col col-span-2 gap-y-1 lg:gap-y-2">
                <label htmlFor="name">Customer Name*</label>
                <input
                  type="text"
                  placeholder="Abdullah"
                  name="name"
                  id="name"
                  required
                  value={saleData.customerName}
                  onChange={(e) =>
                    setSaleData({ ...saleData, customerName: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="number">Contact Number</label>
                <input
                  type="text"
                  placeholder="0300-1234567"
                  name="number"
                  id="number"
                  value={saleData.contactNumber}
                  onChange={(e) =>
                    setSaleData({ ...saleData, contactNumber: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="saleDate">Sale Date*</label>
                <input
                  type="date"
                  required
                  name="saleDate"
                  id="saleDate"
                  value={saleData.saleDate}
                  onChange={(e) =>
                    setSaleData({ ...saleData, saleDate: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="paymentMethod">Payment Method*</label>
                <select
                  name="paymentMethod"
                  id="paymentMethodrequired"
                  value={saleData.paymentMethod}
                  onChange={(e) =>
                    setSaleData({ ...saleData, paymentMethod: e.target.value })
                  }
                >
                  <option>Cash</option>
                  <option>Online</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="saleAmount">Sale Amount*</label>
                <input
                  type="number"
                  placeholder="10000"
                  name="saleAmount"
                  id="saleAmount"
                  required
                  value={saleData.saleAmount}
                  readOnly
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="creditAmount">Credit Amount*</label>
                <input
                  type="number"
                  placeholder="10000"
                  name="creditAmount"
                  id="creditAmount"
                  required
                  value={saleData.creditAmount}
                  onChange={(e) =>
                    setSaleData({ ...saleData, creditAmount: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="debitAmount">Debit Amount*</label>
                <input
                  type="number"
                  placeholder="10000"
                  name="debitAmount"
                  id="debitAmount"
                  required
                  value={saleData.debitAmount}
                  readOnly
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="orderDate">Order Date</label>
                <input
                  type="date"
                  name="orderDate"
                  id="orderDate"
                  value={saleData.orderDate}
                  onChange={(e) =>
                    setSaleData({ ...saleData, orderDate: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="dueDate">Payment Due Date</label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  value={saleData.paymentDueDate}
                  onChange={(e) =>
                    setSaleData({ ...saleData, paymentDueDate: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="payRecieveDate">Payment Received Date*</label>
                <input
                  type="date"
                  required
                  name="payRecieveDate"
                  id="payRecieveDate"
                  value={saleData.paymentReceivedDate}
                  onChange={(e) =>
                    setSaleData({
                      ...saleData,
                      paymentReceivedDate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="salesTax">Sales Tax (%)</label>
                <input
                  type="number"
                  placeholder="5"
                  name="salesTax"
                  id="salesTax"
                  min="0"
                  max="100"
                  value={saleData.salesTax}
                  onChange={(e) =>
                    setSaleData({ ...saleData, salesTax: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:col-span-2">
                <label htmlFor="paymentStatus">Payment Status*</label>
                <select
                  name="paymentStatus"
                  id="paymentStatus"
                  value={saleData.paymentStatus}
                  onChange={(e) =>
                    setSaleData({ ...saleData, paymentStatus: e.target.value })
                  }
                >
                  <option>Paid</option>
                  <option>Unpaid</option>
                  <option>Partially Paid</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:col-span-2">
                <label htmlFor="salePerson">Sale Person*</label>
                <input
                  type="text"
                  placeholder="Shehryar"
                  name="salePerson"
                  id="salePerson"
                  required
                  value={saleData.salesPerson}
                  onChange={(e) =>
                    setSaleData({ ...saleData, salesPerson: e.target.value })
                  }
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2 lg:col-span-4">
                <label htmlFor="comments">Comments</label>
                <textarea
                  type="text"
                  placeholder="We sold this item in less amount than purchase"
                  name="comments"
                  id="comments"
                  value={saleData.comment}
                  onChange={(e) =>
                    setSaleData({ ...saleData, comment: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">Add Entry</button>
            <button type="reset" className="btn btn-outline">Reset</button>
          </div>
        </form>
      </Draggable>

      {showPopup && (
        <Popup
          isSuccess={popupData.isSuccess}
          message={popupData.message}
          onClose={() => setShowPopup(false)}
        />
      )}
      {loader && <Loader />}
    </>
  );
};