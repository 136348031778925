import React, { useEffect, useState } from "react";

import axios from "axios";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Navbar, Footer, Tooltip, Popup } from "../Components";
import { search } from "../assets";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';


export const SalesAnalysis = () => {
  const [productSold, setProductSold] = useState(0)
  const [totalSales, setTotalSales] = useState(0)
  const [totalPurchases, setTotalPurchases] = useState(0)

  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" })


  useEffect(() => {
    getSales()
    getPurchases()
  }, [])

  const getSales = () => {
    axios.get(`${apiUrl}/api/sales/analysis/get`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log(res.data.message);

      // Sales data in cards
      const quantity = res.data.sales.reduce((total, sale) => {
        return total + sale.products.reduce((acc, item) => acc + item.productQuantity, 0);
      }, 0);
      setProductSold(quantity)

      const profit = res.data.sales.reduce((acc, item) => acc + item.saleAmount, 0);
      setTotalSales(profit)

    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData)
      setShowPopup(true);
    })
  }

  const getPurchases = () => {
    axios.get(`${apiUrl}/api/purchase/get`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    }).then(res => {
      console.log(res.data.message);

      const totalQuantity = res.data.purchases.reduce((purchaseAcc, purchase) => purchaseAcc + purchase.products.reduce((productAcc, product) =>
        productAcc + product.itemQuantity, 0), 0);
      setTotalPurchases(totalQuantity)
    }).catch(err => {
      console.error('Error fetching purchases:', err);
      throwError(err, setPopupData);
      setShowPopup(true);
    });
  }
  


  const chart1 = {
    title: "",
    chart: {
      height: 310,
      width: null
    },
    series: [
      {
        name: "Sales Trends Over Time",
        data: [10, 23, 6, 4, 16, 20, 13, 15, 10, 11, 17, 20],
        type: 'column',
        color: '#283891',
        animation: true
      }
    ],
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: {
      title: "",
      lineWidth: 1,
    },
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      x: 10,
      y: 0
    },
    credits: { enabled: false }
  }

  const chart2 = {
    title: "",
    chart: {
      height: 310,
      width: null
    },
    series: [
      {
        name: "Sales Trends Over Time",
        data: [1, 3, 2, 4, 16, 20, 23, 25, 20, 21, 27, 30],
        type: 'line',
        color: '#142256',
        animation: true,
        marker: { radius: 0 }
      },
      {
        name: "Sales Trends Over Time",
        data: [2, 13, 8, 14, 19, 10, 23, 29, 40, 51, 37, 43],
        type: 'line',
        color: '#63D1C6',
        animation: true,
        marker: { radius: 0 }
      }
    ],
    xAxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      height: 220
    },
    yAxis: {
      title: "",
      lineWidth: 1,
      height: 220
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      y: 20
    },
    credits: { enabled: false }
  }

  const chart3 = {
    title: "",
    chart: {
      height: 310,
      width: null
    },
    series: [
      {
        type: 'pie',
        data: [
          {
            name: 'Ink',
            y: 15,
            color: '#01d388',
            collapsed: false
          },
          {
            name: 'Eyelet',
            y: 30,
            color: '#25aae1'
          },
          {
            name: 'Standee',
            y: 55,
            color: '#4162a1'
          }
        ],
        animation: true
      }
    ],
    plotOptions: {
      pie: {
        dataLabels: {
          distance: 0,
          connectorColor: "transparent",
          crop: false,
          formatter: function () {
            return this.percentage.toFixed() + '%'; // Display percentage
          }
        },
        showInLegend: true,
        size: 190
      }
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom'
    },
    credits: { enabled: false }
  }


  return (
    <>
      {/* Navbar */}
      <Navbar />

      <main className="px-6 lg:px-[60px] lg:py-8 py-4 space-y-4 lg:space-y-8 center">

        <section className="grid grid-cols-3 gap-x-2 lg:gap-x-7">
          <div className="flex flex-col lg:gap-y-1 p-2.5 lg:px-10 lg:py-8 bg-[--primary-gray] rounded lg:rounded-[--radius-main]">
            <p className="text-[10px] leading-3 lg:text-base lg:font-bold lg:order-1 text-[--sec-gray]">Total Sales</p>
            <p className="text-[--btn-primary-blue] lg:text-[40px] lg:leading-[48px] font-bold">{totalSales}</p>
          </div>

          <div className="flex flex-col lg:gap-y-1 p-2.5 lg:px-10 lg:py-8 bg-[--primary-gray] rounded lg:rounded-[--radius-main]">
            <p className="text-[10px] leading-3 lg:text-base lg:font-bold lg:order-1 text-[--sec-gray]">Products Sold</p>
            <p className="text-[--btn-primary-blue] lg:text-[40px] lg:leading-[48px] font-bold">{productSold}</p>
          </div>

          <div className="flex flex-col lg:gap-y-1 p-2.5 lg:px-10 lg:py-8 bg-[--primary-gray] rounded lg:rounded-[--radius-main]">
            <p className="text-[10px] leading-3 lg:text-base lg:font-bold lg:order-1 text-[--sec-gray]">Total Purchases</p>
            <p className="text-[--btn-primary-blue] lg:text-[40px] lg:leading-[48px] font-bold">{totalPurchases}</p>
          </div>
        </section>





        <section className="lg:bg-[--primary-gray] rounded lg:rounded-[--radius-main] space-y-4 lg:space-y-11 lg:px-10 lg:py-8 text-xs lg:text-base">

          <div className="grid grid-cols-2 lg:grid-cols-5 gap-y-3 gap-x-2 lg:items-end max-lg:bg-[--primary-gray] max-lg:px-2.5 max-lg:py-4 max-lg:rounded">
            <div className="flex flex-col gap-y-1 col-span-2">
              <div className="flex gap-x-1 lg:gap-x-2 items-center">
                <label htmlFor="KPI">KPI (key performance indicator)</label>
                <Tooltip message="KPI" top={false} />
              </div>
              <div className="relative flex">
                <input type="text" placeholder="Suggest me total sales  with  all the products"
                  className="lg:pr-10 py-[9px] w-full"
                />
                <img src={search} alt="search" className="absolute right-4 top-1/2 -translate-y-1/2" />
              </div>
            </div>

            <div className="flex flex-col gap-y-1">
              <label htmlFor="startDate">Start Date</label>
              <input type="date" name="startDate" id="startDate" />
            </div>

            <div className="flex flex-col gap-y-1">
              <label htmlFor="endDate">End Date</label>
              <input type="date" name="endDate" id="endDate" />
            </div>

            <button className="btn btn-blue w-full lg:mt-7 col-span-2 lg:col-span-1">Submit</button>
          </div>


          <div className="grid lg:grid-cols-3 gap-y-4 gap-x-5">
            <div className="rounded-[--radius-main] p-5 lg:p-8 bg-white nav-shadow">
              <div className="flex justify-between">
                <p>Sales Trends Over Time</p>
                <Tooltip message="Random message" top={true} />
              </div>
              <HighchartsReact highcharts={Highcharts} options={chart1} />
            </div>

            <div className="rounded-[--radius-main] p-5 lg:p-8 bg-white nav-shadow">
              <div className="flex justify-between">
                <p>Sales Trends Over Time</p>
                <Tooltip message="Random message" top={true} />
              </div>
              <HighchartsReact highcharts={Highcharts} options={chart2} />
            </div>

            <div className="rounded-[--radius-main] p-5 lg:p-8 bg-white nav-shadow">
              <div className="flex justify-between">
                <p>Sales Trends Over Time</p>
                <Tooltip message="Random message" top={true} />
              </div>
              <HighchartsReact highcharts={Highcharts} options={chart3} />
            </div>

          </div>

        </section>

      </main>

      {/* Footer */}
      <Footer />


      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
    </>
  );
};
