import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import ReactToPrint from 'react-to-print';

import { Navbar, Footer, SalesEntryForm, UpdateBalance, Popup, BalanceInquiry, Loader } from "../Components";
import { doubleArrow, deleteIcon, search, print, skLogo } from "../assets";
import formatDate from "../utils/formatDate";
import throwError from "../utils/catch";
import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";


export const Sales = () => {
  const [sales, setSales] = useState([]);
  const [customerBalanceInquiry, setCustomerBalanceInquiry] = useState("");
  const [printData, setPrintData] = useState(null);
  const saleRef = useRef();

  const [salesEntryForm, setSalesEntryForm] = useState(false);
  const [updateBalanceForm, setUpdateBalanceForm] = useState(false);
  const [balanceInquiryForm, setBalanceInquiryForm] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [editingCell, setEditingCell] = useState({ saleId: null, field: null });
  const [editedValue, setEditedValue] = useState('');
  const [editedProducts, setEditedProducts] = useState([]);



  useEffect(() => getSales(), []);

  const getSales = () => {
    axios
      .get(`${apiUrl}/api/sales/get`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setSales(res.data.sales);
        console.log(res.data.message);
      })
      .catch((err) => {
        console.error(err);
        throwError(err, setPopupData);
        setSales([]);
      });
  };

  const deleteSale = (id) => {
    setLoader(true);
    axios
      .delete(`${apiUrl}/api/sales/delete/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res.data);
        getSales();
      })
      .catch((err) => {
        console.error(err);
        throwError(err, setPopupData);
        setShowPopup(true);
      })
      .finally(() => setLoader(false));
  };

  // const editSale = (sale) => {
  //   setSales(sale);
  //   setSalesEntryForm(true);
  // };

  const handleInquiryForm = (e) => {
    e.preventDefault();
    if (
      !customerBalanceInquiry.trim() ||
      customerBalanceInquiry.trim() === ""
    ) {
      setShowPopup(true);
      setPopupData({
        isSuccess: false,
        message: "Please provide the customer name",
      });
      return;
    }
    setBalanceInquiryForm(true);
  };

  const closeForm = (setForm) => {
    setForm(false);
    getSales();
  };

  const closeInquiryForm = () => {
    setBalanceInquiryForm(false);
    setCustomerBalanceInquiry("");
  };


  const handleSave = (saleId, field, value) => {

    if (field === 'saleAmount' || field === 'creditAmount' || field === 'debitAmount') {
      if (isNaN(value)) {
        alert('Please enter a valid number');
        return;
      }
    }

    if (field === 'saleDate' && !value) {
      alert('Please select a valid date');
      return;
    }

    axios.put(`${apiUrl}/api/sales/update/${saleId}`, { [field]: value },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }).then((res) => {
        // Update the local sales state
        setSales((prevSales) =>
          prevSales.map((sale) =>
            sale._id === saleId ? { ...sale, [field]: value } : sale
          )
        );
        // Reset editing state
        setEditingCell({ saleId: null, field: null });
        setEditedValue('');
      }).catch((err) => {
        console.error(err);
        throwError(err, setPopupData);
        // Optionally reset the editing state on error
        setSales((prevSales) => [...prevSales]);
        setEditingCell({ saleId: null, field: null });
        setEditedValue('');
      });
  };

  const handleProductChange = (index, field, value) => {
    setEditedProducts((prevProducts) => {
      const newProducts = [...prevProducts];

      // Only allow changes to 'productQuantity' and 'totalCost'
      if (field === 'productQuantity' || field === 'totalCost') {
        newProducts[index][field] = value;
      }

      return newProducts;
    });
  };

  // Commented out but can be used later on
  // const handleAddProduct = () => {
  //   setEditedProducts((prevProducts) => [
  //     ...prevProducts,
  //     {
  //       product: { productName: '' },
  //       productQuantity: 1,
  //       totalCost: 0,
  //       _id: null,
  //     },
  //   ]);
  // };

  // Commented out but can be used later on
  // const handleRemoveProduct = (index) => {
  //   setEditedProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  // };

  const handleSaveProducts = (saleId) => {
    // Validate the edited products before sending
    const valid = editedProducts.every((product) => {
      return (
        product.productQuantity > 0 &&
        product.totalCost >= 0
      );
    });

    if (!valid) {
      alert('Please enter valid product quantities and total costs.');
      return;
    }

    // Prepare data to send to backend
    const productsToSend = editedProducts.map((product) => ({
      product: typeof product.product === 'object' ? product.product._id : product.product,
      productQuantity: product.productQuantity,
      totalCost: product.totalCost,
    }));

    axios.put(`${apiUrl}/api/sales/update/${saleId}`, { products: productsToSend }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      // Update local state
      setSales((prevSales) =>
        prevSales.map((sale) =>
          sale._id === saleId ? { ...sale, products: res.data.sale.products } : sale
        )
      );
      // Reset editing state
      setEditingCell({ saleId: null, field: null });
      setEditedProducts([]);
    }).catch((err) => {
      console.error(err);
      alert('An error occurred while updating the products.');
    });
  };

  const handleCancelEdit = () => {
    setEditingCell({ saleId: null, field: null });
    setEditedProducts([]);
  };


  const handlePrint = (sale) => {
    return new Promise((resolve) => {
      setPrintData(sale);
      setTimeout(resolve, 100);
    });
  };


  const filteredSales = sales.filter((sale) => {
    return sale.products
      .map((product) => product?.product?.productName || "")
      .join(", ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  return (
    <main>
      {/* Navbar */}
      <Navbar />

      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">
        {/* Cards */}
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-5">
          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px]">
            <h2 className="text-base lg:text-[28px] lg:leading-[34px]">
              Sales Entry Form
            </h2>
            <div className="flex gap-x-2">
              <button onClick={() => setSalesEntryForm(true)} className="btn btn-blue max-lg:w-full">Add Entry</button>
              <button onClick={() => setUpdateBalanceForm(true)} className="btn btn-blue lg:hidden max-lg:w-full">Update Balance</button>
            </div>
          </div>

          <div className="hidden bg-[--primary-gray] lg:flex flex-col gap-y-3 items-center justify-center px-4 h-[202px]">
            <h2>Update Balance</h2>
            <button onClick={() => setUpdateBalanceForm(true)} className="btn btn-blue">Update Balance</button>
          </div>

          <form
            onSubmit={handleInquiryForm}
            className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center px-4 py-5 lg:h-[202px]"
          >
            <h2 className="text-base lg:text-[28px] lg:leading-[34px]">
              Balance Inquiry
            </h2>
            <input
              className="rounded w-full lg:w-[280px]"
              type="text"
              placeholder="Customer Name"
              value={customerBalanceInquiry}
              onChange={(e) => setCustomerBalanceInquiry(e.target.value)}
            />
            <button className="btn btn-blue text-xs lg:text-base w-full lg:w-[139px]">Find Sale</button>
          </form>
        </section>



        {/* Table */}
        <section>
          <div className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input type="text" placeholder="Search Item" className="rounded-[22px] pl-reg lg:pr-10" value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} />

              <img
                src={search}
                alt="search"
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="overflow-x-auto mb-4 lg:mb-16">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="salesId">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "saleId", "number")}
                    >
                      <span>Sales ID</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="customerName">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "customerName", "string")}
                    >
                      <span>Customer Name</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="item">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "item", "string")}
                    >
                      <span>Item</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="payMethod">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(sales, setSales, "paymentMethod", "string")}
                    >
                      <span>Payment Method</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="saleAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "saleAmount", "number")}
                    >
                      <span>Sale Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="creditAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(sales, setSales, "creditAmount", "number")}
                    >
                      <span>Credit Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="debitAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(sales, setSales, "debitAmount", "number")}
                    >
                      <span>Debit Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="saleDate">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "saleDate", "date")}
                    >
                      <span>Sale Date</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {filteredSales.length > 0 ? (
                  filteredSales.map((sale) => (
                    <tr key={sale._id} className="*:px-4 *:py-2 h-11 ps-reg">

                      {/* Sale ID */}
                      <td
                        headers="salesId" title="Read-Only">
                        {sale.saleId < 100
                          ? sale.saleId < 10
                            ? "00" + sale.saleId
                            : "0" + sale.saleId
                          : sale.saleId}
                      </td>

                      {/* Customer Name */}
                      <td headers="customerName" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ saleId: sale._id, field: 'customerName' });
                          setEditedValue(sale.customerName.join(' '));
                        }}
                      >
                        {editingCell.saleId === sale._id && editingCell.field === 'customerName' ? (
                          <input
                            type="text"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() =>
                              handleSave(sale._id, 'customerName', editedValue.split(' '))
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(sale._id, 'customerName', editedValue.split(' '));
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          sale.customerName.join(' ')
                        )}
                      </td>

                      {/* Item */}
                      <td
                        headers="item" title="Double-Click to Edit Qty and Rs"
                        onDoubleClick={() => {
                          setEditingCell({ saleId: sale._id, field: 'products' });
                          setEditedProducts(sale.products.map((product) => {
                            if (product.product) {
                              return {
                                ...product,
                                productId: product._id,
                                productName: product.product,
                              };
                            } else {
                              return {
                                ...product,
                                productId: product._id,
                                productName: product.product,
                              };
                            }
                          })
                          );
                        }}
                      >
                        {editingCell.saleId === sale._id && editingCell.field === 'products' ? (
                          <div>
                            {editedProducts.map((product, index) => (
                              <div key={product._id || index} className="flex items-center space-x-2">
                                {/* Product Name */}
                                <span>{product.product ? product.product.productName : "Deleted Product"}</span>
                                {/* Quantity */}
                                <input
                                  type="number"
                                  value={product.productQuantity}
                                  onChange={(e) => handleProductChange(index, 'productQuantity', e.target.value)}
                                  min="1"
                                  placeholder="Quantity"
                                  className="border p-1"
                                  disabled={!product.product} // Here, we are disabling to edit if product is null
                                />
                                {/* Total Cost */}
                                <input
                                  type="number"
                                  value={product.totalCost}
                                  onChange={(e) => handleProductChange(index, 'totalCost', e.target.value)}
                                  min="0"
                                  step="0.01"
                                  placeholder="Total Cost"
                                  className="border p-1"
                                  disabled={!product.product} // Here, we are disabling to edit if product is null
                                />
                                {/* Remove Button */}
                                {/* <button onClick={() => handleRemoveProduct(index)} className="text-red-500">
                                  Remove
                                </button> */}
                              </div>
                            ))}
                            {/* Add and Save Buttons */}
                            <div className="mt-2">
                              {/* <button onClick={handleAddProduct} className="mr-2 p-1 bg-green-500 text-white">
                                Add Product
                              </button> */}
                              <button onClick={() => handleSaveProducts(sale._id)} className="mr-2 p-1 bg-blue-500 text-white">
                                Save
                              </button>
                              <button onClick={handleCancelEdit} className="p-1 bg-gray-500 text-white">
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {sale.products.map((el, index) => (
                              <div key={el._id || index} className="w-max">
                                {el.product ? (
                                  <>
                                    {el?.product?.productName} - {el?.productQuantity} qty - Rs. {el?.totalCost}
                                  </>
                                ) : (
                                  <>
                                    Deleted Product - {el.productQuantity} qty - Rs. {el.totalCost}
                                  </>
                                )}
                                {index < sale.products.length - 1 && ', '}
                              </div>
                            ))}
                          </div>
                        )}
                      </td>


                      {/* Payment Method */}
                      <td
                        headers="payMethod" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ saleId: sale._id, field: 'paymentMethod' });
                          setEditedValue(sale.paymentMethod);
                        }}
                      >
                        {editingCell.saleId === sale._id && editingCell.field === 'paymentMethod' ? (
                          <input
                            type="text"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleSave(sale._id, 'paymentMethod', editedValue)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(sale._id, 'paymentMethod', editedValue);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          sale.paymentMethod
                        )}
                      </td>


                      {/* Sale Amount */}
                      <td
                        headers="saleAmount" title="Edit with Precautions"
                        onDoubleClick={() => {
                          setEditingCell({ saleId: sale._id, field: 'saleAmount' });
                          setEditedValue(sale.saleAmount);
                        }}
                      >
                        {editingCell.saleId === sale._id && editingCell.field === 'saleAmount' ? (
                          <input
                            type="number"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleSave(sale._id, 'saleAmount', parseFloat(editedValue))}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(sale._id, 'saleAmount', parseFloat(editedValue));
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          sale.saleAmount
                        )}
                      </td>


                      {/* Credit Amount */}
                      <td
                        headers="creditAmount" title="Read-Only"
                      // onDoubleClick={() => {
                      //   setEditingCell({ saleId: sale._id, field: 'creditAmount' });
                      //   setEditedValue(sale.creditAmount);
                      // }}
                      >
                        {/* {editingCell.saleId === sale._id && editingCell.field === 'creditAmount' ? (
                          <input
                            type="number"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() =>
                              handleSave(sale._id, 'creditAmount', parseFloat(editedValue))
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(sale._id, 'creditAmount', parseFloat(editedValue));
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          sale.creditAmount
                        )} */}
                        {sale.creditAmount}
                      </td>


                      {/* Debit Amount */}
                      <td
                        headers="debitAmount" title="Read-Only"
                      // onDoubleClick={() => {
                      //   setEditingCell({ saleId: sale._id, field: 'debitAmount' });
                      //   setEditedValue(sale.debitAmount);
                      // }}
                      >
                        {/* {editingCell.saleId === sale._id && editingCell.field === 'debitAmount' ? (
                          <input
                            type="number"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() =>
                              handleSave(sale._id, 'debitAmount', parseFloat(editedValue))
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(sale._id, 'debitAmount', parseFloat(editedValue));
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          sale.debitAmount
                        )} */}
                        {sale.debitAmount}
                      </td>


                      {/* Sale Date */}
                      <td
                        headers="saleDate" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ saleId: sale._id, field: 'saleDate' });
                          setEditedValue(
                            sale.saleDate ? new Date(sale.saleDate).toISOString().split('T')[0] : ''
                          );
                        }}
                      >
                        {editingCell.saleId === sale._id && editingCell.field === 'saleDate' ? (
                          <input
                            type="date"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleSave(sale._id, 'saleDate', editedValue)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(sale._id, 'saleDate', editedValue);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          formatDate(sale.saleDate)
                        )}
                      </td>


                      <td headers="actions" className="flex gap-x-2 w-24">
                        <ReactToPrint
                          trigger={() => (
                            <img src={print} alt="Print button" className="inline-block cursor-pointer" />
                          )}
                          onBeforeGetContent={() => handlePrint(sale)}
                          content={() => saleRef.current} />
                        {/* <img
                          src={edit}
                          alt="Edit button"
                          className="inline-block cursor-pointer"
                          onClick={editSale}
                        /> */}
                        <img
                          src={deleteIcon}
                          alt="Delete button"
                          className="inline-block cursor-pointer"
                          onClick={() => deleteSale(sale._id)}
                        />
                      </td>

                    </tr>

                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-[#E83535] font-bold p-4">
                      No products found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>


      {/* Table to print */}
      <section ref={saleRef} className="p-10 print:block hidden space-y-8">
        <div className="flex justify-between items-center">
          <img src={skLogo} alt="SK Printing Solution" className="h-10" />
          <h2 className="text-center">Invoice</h2>
          <p className="ps-bold">Date:{formatDate(Date.now())}</p>
        </div>

        <div className="space-y-5">
          <div className="space-y-3">
            <div>
              <span className="pl-bold">Invoice # </span>
              <span>{printData?.saleId}</span>
            </div>
            <div>
              <span className="pl-bold">Customer Name: </span>
              <span>{printData?.customerName.join(" ")}</span>
            </div>
            {printData?.orderDate &&
              <div>
                <span className="pl-bold">Order date: </span>
                <span>{formatDate(printData?.orderDate)}</span>
              </div>
            }
            <div>
              <span className="pl-bold">Sale date: </span>
              <span>{formatDate(printData?.saleDate)}</span>
            </div>
            <div>
              <span className="pl-bold">Payment received date: </span>
              <span>{formatDate(printData?.paymentReceivedDate)}</span>
            </div>
            <div>
              <span className="pl-bold">Payment method: </span>
              <span>{printData?.paymentMethod}</span>
            </div>
          </div>


          <div className="space-y-3">
            <h3>Details</h3>
            <table className="*:*:p-2 w-full border border-[#00000033]">
              <tr className="bg-[--primary-gray] divide-x divide-[#00000033]">
                <th>Product Name</th>
                <th>Price / unit</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
              {printData?.products
                ?.filter((sale) => sale.product)
                ?.map((sale, i) => (
                  <tr
                    key={sale._id}
                    className={`${i % 2 !== 0 && "bg-[--primary-gray]"} divide-x divide-[#00000033]`}
                  >
                    <td>{sale.product ? sale.product.productName : "Deleted Product"}</td>
                    <td>
                      Rs. {sale.product ? sale.product.sellingPrice : "N/A"}
                    </td>
                    <td>{sale.productQuantity}</td>
                    <td>Rs. {sale.totalCost}</td>
                  </tr>
                ))}
            </table>
          </div>

          <div className="space-y-3">
            <h3>Transactions</h3>
            <table className="*:*:p-2 w-full border border-[#00000033]">
              <tr className="bg-[--primary-gray] divide-x divide-[#00000033]">
                <th>Date</th>
                <th>Credit amount</th>
                <th>Debit amount</th>
              </tr>
              {printData?.amountHistory?.map((pay, i) => (
                <tr key={pay._id} className={`${i % 2 !== 0 && "bg-[--primary-gray]"} divide-x divide-[#00000033]`}>
                  <td>{formatDate(pay.transactionDate)}</td>
                  <td>Rs. {pay.credit}</td>
                  <td>Rs. {pay.debit}</td>
                </tr>
              ))}
            </table>
          </div>

          <div className="bg-[--primary-gray]">
            <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x">
              <h3 className="col-span-8 text-lg text-right">Total Amount</h3>
              <p className="col-span-2">Rs. {printData?.saleAmount}</p>
            </div>
            <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x bg-white">
              <h3 className="col-span-8 text-lg text-right">Credit Amount / Amount Paid</h3>
              <p className="col-span-2">Rs. {printData?.creditAmount}</p>
            </div>
            <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x">
              <h3 className="col-span-8 text-lg text-right">Debit Amount / Balance</h3>
              <p className="col-span-2">Rs. {printData?.debitAmount}</p>
            </div>
          </div>

        </div>
      </section>

      {/* Footer */}
      <Footer />





      {/* Forms */}
      {salesEntryForm && (
        <SalesEntryForm onClose={() => closeForm(setSalesEntryForm)} />
      )}
      {updateBalanceForm && (
        <UpdateBalance onClose={() => closeForm(setUpdateBalanceForm)} />
      )}
      {balanceInquiryForm && (
        <BalanceInquiry
          onClose={closeInquiryForm}
          customerName={customerBalanceInquiry}
        />
      )}

      {/* Popup */}
      {showPopup && (
        <Popup
          isSuccess={popupData.isSuccess}
          message={popupData.message}
          onClose={() => setShowPopup(false)}
        />
      )}
      {loader && <Loader />}
    </main>
  );
};
